import React from 'react';
import Layout from '../components/Layout';
import { Link, graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import Block from '../components/Block';
import SubscribeForm from '../components/Subscribe';
import BG from '../images/subscribe.png'

const Subscribe = ({ data, location }) => {
	const siteTitle = data.site.siteMetadata?.title || 'Title';

	return (
		<Layout location={location} title="Subscribe | Roman Musatkin">
			<div
				style={{
					position: 'fixed',
					height: '100%',
					width: '100%',
					zIndex: -1,
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
					backgroundImage: 'url(' + BG + ')',
					backgroundRepeat: 'no-repeat',
					backgroundSize: '100vh auto',
					backgroundPosition: 'center center',
					backgroundAttachment: 'fixed'
				}}
			/>
			<Block>
				<div style={{textAlign:"center", marginTop:"20vh", marginBottom:"20vh"}}>
					<SubscribeForm/>
				</div>
			</Block>
		</Layout>
	);
};

export default Subscribe;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
			title
			date(formatString: "MMM D")
			description
			tags
			author
        }
      }
    }
  }
`;
